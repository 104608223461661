import { paths } from '~/lib/paths'
import { LocaleCode } from '~/types/locales'
import { ModelApiKey } from '~/types/models'

export default function () {
  const { locale } = useI18n()
  const localePath = useLocalePath()

  const localePathByType = (typename: string, slug: string, query?: string) => {
    let path
    const queryPath = query ? `?category=${query}` : ''

    switch (typename) {
      case 'IndexRecord':
        path = localePath('/')
        break
      case 'PageRecord':
        path = localePath(`/${slug}${queryPath}`)
        break
      case 'ArticleRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Article].path}/${slug}${queryPath}`)
        break
      case 'ProgramProductRecord':
        path = localePath(
          `/${paths[locale.value as LocaleCode][ModelApiKey.ProgramProduct].path}/${slug}`
        )
        break
      case 'ShopProductRecord':
        path = localePath(
          `/${paths[locale.value as LocaleCode][ModelApiKey.ShopProduct].path}/${slug}`
        )
        break
      case 'ShopCategoryRecord':
        path = localePath(
          `/${paths[locale.value as LocaleCode][ModelApiKey.ShopCategory].path}/${slug}`
        )
        break
      default:
        return null
    }
    return path
  }

  return {
    localePathByType
  }
}
